<template>
  <div class="comprehensive">
      <div class="banner">
            <div class="container bannerContainer">
            <div>
                <img src="../../assets/images/comprehensiveBanner3.png" alt="">
                <router-link to="/ComprehensiveInputForm">立即体验</router-link>
            </div>
            <img class="bannerBg" src="../../assets/images/comprehensiveBanner2.png" alt="">
            </div>
        </div>

        <div id="content1" class="content content1">
            <div class="container">
                <div class="contentDiv">
                    <p><span>01</span>论文查重<br>万方<span>权威</span>查重系统</p>
                    <div>采用自主知识产权的全新相似度检测算法，一次查重4个报告。24小时监测，多元化场景，安全保密海量数据资源。</div>
                </div>
                <img class="contentImg" src="../../assets/images/comprehensiveImg1.png" alt="">
            </div>
        </div>
        <div id="content2" class="content content2">
            <div class="container">
                <img class="contentImg" src="../../assets/images/comprehensiveImg2.png" alt="">
                <div class="contentDiv">
                    <p><span>02</span>申领DCI<br>快速确认权属</p>
                    <div>查重完成立即申领DCI。全网监测，快速确权，人工智能精准识别作品版权权属信息，DCI 申领秒级反馈，实时获得 DCI。</div>
                </div>
            </div>
        </div>
        <div id="content3" class="content content3">
            <div class="container">
                <div class="contentDiv">
                    <p><span>03</span>版权登记<br>数字作品版权权属证明</p>
                    <div>凭 DCI 可申请国家版权局作品登记证书（数字版），数字化版权服务新模式，更高效便捷更省钱。</div>
                </div>
                <img class="contentImg" src="../../assets/images/comprehensiveImg3.png" alt="">
            </div>
        </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.banner{
  width: 100%;
  height: 440px;
  position: relative;
  background: url(../../assets/images/comprehensiveBanner.png)no-repeat;
  background-size: cover;
  .bannerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      p{
        font-size: 48px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #000000;
        line-height: 81px;
        strong{
          color: #056AE2;
        }
      }
      span{
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
        font-family: 'SourceHanSansCN, SourceHanSansCN';
        color: #333763;
        padding-right: 20px;
      }
      img{
          height: 130px;
          margin: 0;
      }
      a{
          display: inline-block;
          height: 42px;
          background: linear-gradient(90deg, #0F4893 0%, #0380E1 100%);
          border-radius: 4px;
          line-height: 42px;
          padding: 0 20px;
          margin-top: 50px;
          color: #FFFFFF;
          font-size: 18px;
          font-family: 'SourceHanSansCN, SourceHanSansCN';
      }
      a:hover{
          background: linear-gradient(90deg, #0380E1 0%, #0F4893 100%);
      }
    }
    img{
      height: 440px;
      margin-right: -100px;
    }
  }
}
.content{
    padding: 60px 0;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .contentDiv{
            width: calc(100% - 540px);
            p{
                font-size: 36px;
                font-family: 'SourceHanSansCN, SourceHanSansCN';
                font-weight: bold;
                color: #252D47;
                line-height: 46px;
                span{
                    color: #0163FF;
                    font-weight: bold;
                }
                span:nth-child(1){
                    display: block;
                    font-size: 48px;
                    font-family: 'DIN';
                    font-weight: 900;
                    color: #CCDBF2;
                    line-height: 52px;
                }
            }
            div{
                margin-top: 40px;
                font-size: 18px;
                font-family: 'SourceHanSansCN, SourceHanSansCN';
                font-weight: 400;
                color: #252D47;
                line-height: 28px;
            }
        }
        .contentImg{
            width: 480px;
        }
    }
}
.content1{
    background: url('../../assets/images/comprehensiveBg1.png')no-repeat center;
    background-size: cover;
}
.content2{
    background: url('../../assets/images/comprehensiveBg2.png')no-repeat center;
    background-size: cover;
}
.content3{
    background: url('../../assets/images/comprehensiveBg3.png')no-repeat center;
    background-size: cover;
}

// 适配移动端
@media screen and (max-width: 991px){
  .banner{
    height: 250px;
    div{
        padding: 20px 10px;
        img{
            max-width: 100%;
            height: auto!important;
        }
        a{
            margin-top: 20px!important;
        }
    }
    .bannerBg{
        display: none;
    }
  }
  .content{
    padding: 20px 0;
    .container{
        display: block;
        .contentDiv{
            width: 100%;
            div{
                margin-top: 20px;
            }
        }
        .contentImg{
            max-width: 100%;
        }
    }
  }
  .content2{
      position: relative;
      padding-bottom: 320px;
      .container{
        .contentImg{
            position: absolute;
            max-width: calc(100% - 32px)!important;
            bottom: 20px;
        }
      }
  }
}
</style>
